import React, { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"

import {
  Anchor,
  AnchorButton,
  Column,
  Container,
  FavoriteIcon,
  Icon,
  IconTag,
  Img,
  RichText,
  Row,
  Seperator,
  SpecificationItem,
  Taxonomy,
} from "@atoms"
import { IIcontagProps } from "@atoms/icontag/_icontag.interface"
import { IImageProps } from "@atoms/img/_img.interface"
import { ISpecificationItem } from "@atoms/specificationitem/_specificationitem.interface"
import { ENABLE_DISABLE_VALUE, LOGIN_TYPES } from "@helpers/constants/generic"
import getPaywallData from "@helpers/dataFunctions/getPaywallData"
import { useMediaQuery } from "@helpers/hooks"
import useUserProfile from "@helpers/hooks/useUserProfile"
import {
  BarcodeCollection,
  BookmarkWithButton,
  ButtonGroup,
  ContentWithScroll,
  ImageGrid,
  TableWithScroll,
  TextBlock,
} from "@molecules"
import { IPopup } from "@molecules/popup/_popup.interface"
import { CarouselWrapper, LoginPopup, Paywall, PaywallNotification } from "@organisms"
import { IArticleTeaserBannerProps } from "@organisms/articleBanner/_article-banner.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import { getApiDomainAndLang, middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import { EVENTS } from "@utils/gtmEvents"
import { dataLayerPush } from "@utils/gtmutils"
import {
  checkForLoginModal,
  getAssetPrefixUrl,
  isFinePrintPaywalled,
  isSomeFinePrintPaywalled,
} from "@utils/helper"
import { isPaywallProtected } from "@utils/paywallUtilities"
import { getApplicationPathFromURL } from "@utils/session-util/sessionHandler"
import { encodeString } from "@utils/sha256"
import { AES } from "crypto-js"
import Router from "next/router"
import { addUser, resetUser, setUserProfile } from "store/actions/ActionCreators"
import { setUserStateGlobally, updatePaywallContent } from "store/actions/CommonActionCreators"
import { addRatings, getRatingsCount, resetRatings } from "store/actions/RatingsActionCreators"
import { setSession } from "store/actions/SessionCreators"
import { IProductDetailProps } from "./_csvJapanProductDetail.interface"

interface StateProps {
  user?: any
  ratings?: any
  paywalledContent: boolean
}

interface DispatchProps {
  resetRatings: (ratings: any) => void
  setUserProfile: (profile: any) => void
  resetUserAndProfile: (user: any) => void
  updateRatingsCount: (ratings: any) => void
  updatePaywallContent: (isPaywall: boolean) => void
  setSession: (session: string, csrf: string) => void
  addUserData: (user: any) => void
  resetUserData: (user: any) => void
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) => void
}

type Props = StateProps & IProductDetailProps & DispatchProps

const CSVJapanProductDetail = (props: Props) => {
  const {
    prodImages,
    productCategory,
    title,
    iconTags,
    description,
    prodInfoHeading,
    prodInfoTaxonomies,
    packInfoHeading,
    packInfoTaxonomies,
    headeractiveIngredientInfo,
    byInfoHeading,
    byInfoTaxonomies,
    stInfoHeading,
    stInfoTaxonomies,
    wdInfoHeading,
    wdInfoTaxonomies,
    otherInfoTaxonomies,
    imageGrid,
    showInCard,
    downloadGridImages,
    variousCodes,
    ctaButtonGroup,
    latestUpdates,
    notesAboutProduct,
    barCodes,
    productOverViewHeading,
    sandozVersion,
    originalVersion,
    primaryButtonUrl,
    primaryButtonLabel,
    primaryIsExternalUrl,
    secondaryButtonLabel,
    secondaryButtonUrl,
    secondaryIsExternalUrl,
    tertiaryButtonLabel,
    tertiaryButtonUrl,
    tertiaryIsExternalUrl,
    downloadTabletXLS,
    pdfButtonGroup,
    pdfButtonGroupHeading,
    bookmark,
    id,
    productManager,
    footerHeading,
    footerDescription,
    activeIngredientDescription,
    activeIngredientHeading,
    ratings,
    user,
    resetRatings,
    setUserProfile,
    resetUserAndProfile,
    updateRatingsCount,
    fieldPaywallRoles,
    fieldPaywallRolesOTC,
    finePrint,
    showInCardStatus,
    barcodeDetails,
    sanImgAddImages,
    productID,
    basistextLabel,
    paywall,
    addUserData,
    setSession,
    setUserStateGlobally,
    resetUserData,
    referenceText
  } = props

  const getCSVPaywalledData = (value: any) => {
    return isPaywallProtected(value).isPaywallProtectedFlag ? "" : value
  }

  const getImageGridType = (imgarr: any) => {
    const isMobile = useMediaQuery("(max-width: 767px)")
    if (isMobile || imgarr?.length > 5) {
      return "grid"
    }
    return "default"
  }

  useEffect(() => {
    if (
      ctaButtonGroup?.records &&
      !isPaywallProtected(ctaButtonGroup?.records).isPaywallProtectedFlag
    ) {
      const imgObj = {} as any
      const xlsObj = {} as any
      const options = {} as any
      options.attributes = {} as any
      options.attributes.target = "_self"

      imgObj.uri = downloadGridImages?.url ?? ""
      imgObj.title = downloadGridImages?.cta_label ?? ""
      imgObj.options = options

      xlsObj.uri = downloadTabletXLS?.url ?? ""
      xlsObj.title = downloadTabletXLS?.cta_label ?? ""
      xlsObj.options = options

      if (downloadGridImages?.url) {
        ctaButtonGroup?.records?.push(imgObj)
      }
      if (downloadTabletXLS?.url) {
        ctaButtonGroup?.records?.push(xlsObj)
      }
    }
}, [])

const handleScroll = (htmlElement: any) => {
  const position = htmlElement?.getBoundingClientRect()
  const floatingElement = document?.querySelector(".floatingBanner")
  const eyebrowMenuElement = document?.querySelector(".eyebrow-menu")

  const floatingElementHeight = floatingElement
    ? floatingElement?.getBoundingClientRect()?.height
    : 0
  const eyebrowMenuElementHeight = eyebrowMenuElement
    ? eyebrowMenuElement?.getBoundingClientRect()?.height
    : 0

  const scrollPosition =
    position?.top + window?.scrollY - (floatingElementHeight + eyebrowMenuElementHeight)
  // Check if the scroll position is within the document bounds
  const maxScroll = document?.documentElement?.scrollHeight - window?.innerHeight
  const finalScrollPosition = Math?.min(Math?.max(scrollPosition, 0), maxScroll)

  window.scrollTo({
    left: position?.left + window?.scrollX,
    top: finalScrollPosition,
    behavior: "auto",
  })
}

useEffect(() => {
  const hashedValue = window?.location?.hash.toLowerCase();
  
  const hashconvertedvalue = hashedValue?.includes("#")
    ? hashedValue?.split("#")?.[1]?.split("/")?.[0]
    : hashedValue?.split("/")?.[0]

  const value = (hashedValue === "#fineprint")
    ? isFinePrintPaywalled(finePrint) ? "paywall" : hashconvertedvalue
    : hashconvertedvalue

  const targetElement = document?.querySelector(`[id^="${value}"]`)
  if (targetElement) {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry?.target === targetElement) {
          handleScroll(targetElement)
        }
      }
    })
    resizeObserver?.observe(targetElement)
    setTimeout(() => {
      handleScroll(targetElement)
    }, 0)
  }
}, [])

  const productContent = {
    taxonomyLabel: productCategory,
    heading: title,
    content: description,
    bookMarkData: { bookmarkStatus: true, className: "bookmark" },
  }

  const applicationConfigContext = useContext(ApplicationContext)
  const { applicationHeaderData, applicationConfiguration } = useContext(ApplicationContext)

  const isPimProduct: boolean | undefined =
    applicationConfigContext?.applicationConfiguration?.isPimProduct

  const paywallPosition = applicationConfiguration?.siteConfig?.content_protection_position ?? "top"

  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const [showCredentialsError, setShowCredentialsError] = useState<boolean>(false)
  let totalFavoriteCount = 0
  const dispatch: Dispatch<any> = useDispatch()

  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])
  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)
  const applicationId: any = useSelector((state: any) => state?.appSession?.applicationId)

  const selectedProduct = favourite?.filter((f: any) => parseInt(f.node_id) === Number(id))
  totalFavoriteCount = selectedProduct?.length > 0 ? selectedProduct?.[0]?.count : 0

  useMemo(() => {
    for (const iterator in userSpecificFav) {
      if (parseInt(userSpecificFav[iterator].node_id) === Number(id)) {
        setIsFavouriteSelected(true)
      }
    }
  }, [userSpecificFav])

  const favouriteHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  // *********** Ratings check ***************
  const { fetchUserProfile, fetchUserRatingsCount } = useUserProfile()
  const updateBookMarks = async (id: number, type: string, state: boolean) => {
    const ratingsResponse = await middlewarePostAPI(config?.onPrem?.RATINGS_API_URL, {
      entity_id: id,
      entity_type: "path_alias",
      flag_id: type,
      flag_action: !state ? "flag" : "unflag",
    })

    // remove ratings data from store
    resetRatings({ id: undefined })

    if (!ratingsResponse.fail) {
      // success update card & update user profile
      const profileResponse = await fetchUserProfile()
      if (!profileResponse?.fail) {
        setUserProfile(profileResponse)
        getUserRatingsCount()
      } else {
        resetUserAndProfile({})
      }
    } else {
      // bookmark failed
      console.log("Faild to rate!")
    }
  }

  const getUserRatingsCount = async () => {
    const countResponse = await fetchUserRatingsCount()
    if (!countResponse.fail) {
      updateRatingsCount(countResponse.response)
    } else {
      console.log("Failed to fetch ratings count!")
    }
  }

  useEffect(() => {
    if (ratings?.ratings && ratings?.ratings?.id) {
      if (user && user.isLoggedIn) {
        updateBookMarks(
          ratings.ratings?.id,
          ratings.ratings?.state?.type,
          ratings.ratings?.state?.isChecked,
        )
      } else {
        checkForLoginModal(true).then((isShow: boolean) => {
          if (isShow) {
            // show login popup
            setShowLoginPopUp(true)
          }
        })
      }
    }
  }, [ratings, user])

  useEffect(() => {
    getUserRatingsCount()
    if (fieldPaywallRoles?.length && !user?.isLoggedIn) {
      props?.updatePaywallContent(true)
    }
  }, [])

  //for OTC paywall check
  useEffect(() => {
    if (!fieldPaywallRolesOTC?.length && !user?.isLoggedIn) {
      props.updatePaywallContent(true)
    }
  }, [])

  const handleBookmarks = (state: any) => {
    if (id) {
      if (user.isLoggedIn) {
        updateBookMarks(id, state?.type, state.isChecked)
      } else {
        setShowLoginPopUp(true)
      }
    } else {
      console.log("Node id is not found!")
    }
  }

  const formProps = applicationHeaderData?.headerData?.loginFormData

  const popupProps: IPopup = {
    showPopup: showLoginPopUp,
    showOverlay: showLoginPopUp,
    children: "",
    spinnerIcon: getAssetPrefixUrl("/assets/icons/spinner.svg"),
    closeIcon: {
      url: getAssetPrefixUrl("/assets/icons/close.svg"),
      altText: "close icon",
    },
    handlePopupShow: setShowLoginPopUp,
  }

  const bannerCTAProps: IArticleTeaserBannerProps = {
    banner: {
      heading: "New Customer",
      content: "<p>Relationships matter.</p><p>We are happy to connect with you</p>",
      buttonLabel: "Sign up",
      link: "#",
      linkExternal: true,
    },
    articleBannerImage: {
      desktopImage: {
        src: getAssetPrefixUrl("/assets/images/Desktop_Quiz_Article_Teaser_Image.jpg"),
        alt: "Sandoz Logo Desktop",
        width: 1440,
        height: 800,
      },
      mobileImage: {
        src: getAssetPrefixUrl(
          "/assets/images/Sandoz_Images/Article Teaser (Quiz)/Mobile_Quiz_Article_Teaser_Image.jpg",
        ),
        alt: "Sandoz Logo Mobile",
        width: 390,
        height: 280,
      },
    },
  }
  const handleForgotPassword = () => {
    setShowLoginPopUp(false)
  }

  const handleConfirmation = () => {
    setShowLoginPopUp(false)
  }
  // ****************** Ratings end ***********

  const getRoles = Array.from(new Set(fieldPaywallRoles))
  const filterdRoles = JSON.stringify(getRoles).replace(/[^.,a-zA-Z0-9]/g, " ")

  const checkErrorCode = (input: any) => {
    let errorCode = false
    if (Array.isArray(input)) {
      errorCode = input?.every((item: any) =>
        item?.description?.every((desc: any) => desc?.error_code === 410),
      )
    } else {
      errorCode = input?.rowsvalues?.every((item: any) =>
        item?.every((val: any) => val?.value === ""),
      )
    }
    return errorCode
  }

  const renderTable = () => {
    return (
      <>
        {checkErrorCode(showInCard) ? (
          <></>
        ) : (
          <TableWithScroll
            {...showInCard}
            tableHeaderClass="productFamilyCardContent-table-col"
            tableColumnClass="productFamilyCardContent-table-td"
          />
        )}
      </>
    )
  }

  const nullCheckforDesc = (array: any[], key: string) => {
    return array?.every((item: any) => item?.[key] === null)
  }

  const [paywallData, setPaywallData] = useState<any>(null)

  useMemo(async () => {
    const data = await getPaywallData(applicationConfiguration)
    setPaywallData(data)
  }, [paywall])

  const handleOnSubmit = async (e: any) => {
    e?.preventDefault()
    setShowIndicator(true)
    const loginData = {
      username: e.target?.elements?.email?.value,
      password: e.target?.elements?.password?.value,
    }

    const responseLogin: any = await middlewarePostAPI(config?.onPrem?.LOGIN_API_URL, loginData)
    if (!responseLogin.fail) {
      setShowCredentialsError(false)

      // GTM track
      const trackLogInEvent = {
        user_id: encodeString(loginData.username),
      }
      dataLayerPush(EVENTS.LOGIN, trackLogInEvent)

      // save response to state
      const updateLoginData = {
        ...responseLogin,
        loginType: LOGIN_TYPES.SOLAR,
      }
      addUserData(updateLoginData)
      setUserStateGlobally(true, LOGIN_TYPES.SOLAR)

      // set user details in cookie
      const csrfToken = responseLogin?.response?.session_data?.csrf_token
      const sessionId = responseLogin?.response?.session_data?.hashedSessionId

      /**
       * save sessionId to cookie & redux (without saving any storage)
       * set httpOnly cookie
       */
      setSession(sessionId, csrfToken)
      const salt = process.env.USER_COOKIE_SALT as string
      const isLocalhost = !!location.hostname.includes("localhost")
      const url = getAssetPrefixUrl(
        `/api/data-provider/?id=user&appid=${applicationId}`,
        isLocalhost,
      )
      const { feDomain } = getApiDomainAndLang()

      const response = await fetch(url, {
        headers: {
          sessionId: AES.encrypt(sessionId, salt).toString(),
          csrfToken: AES.encrypt(csrfToken, salt).toString(),
          domainPath: getApplicationPathFromURL(),
          fedomain: feDomain,
        },
      })
      await response.json()

      // fetch user profile
      const profileResponse = await fetchUserProfile()
      if (!profileResponse?.fail) {
        setUserProfile(profileResponse)
      } else {
        console.log("Failed to fetch profile response!")
      }

      Router.reload()
    } else {
      resetUserData(responseLogin)
      setShowCredentialsError(true)
    }
    setShowIndicator(false)
  }

  return (
    <div>
      {paywall ? (
        paywallData ? (
          <Paywall
            onSubmit={handleOnSubmit}
            form={formProps}
            showCredentialsError={paywallData?.showCredentialsError}
            wrongCredentialsText={paywallData?.wrongCredentialsText}
            publicTeaserType={paywallData?.publicTeaserType}
            specificDescriptionText={paywallData?.specificDescriptionText}
            descriptionText={paywallData?.descriptionText}
            loginBlock={paywallData?.loginBlock}
            registerBlock={paywallData?.registerBlock}
          />
        ) : (
          <></>
        )
      ) : (
        <>
          {!user?.isLoggedIn &&
            (paywallPosition === "top" || paywallPosition === "both") &&
            fieldPaywallRoles &&
            fieldPaywallRoles?.length > 0 && (
              <>
                {applicationConfigContext?.applicationConfiguration?.paywallNotice && (
                  <Container id="paywall">
                    <Row isTemplateWrapper>
                      <PaywallNotification
                        paywallNotice={
                          applicationConfigContext?.applicationConfiguration?.paywallNotice
                        }
                        roles={filterdRoles}
                        loginHandler={() => setShowLoginPopUp(true)}
                      />
                    </Row>
                  </Container>
                )}
              </>
            )}
          <Container narrow>
            {prodImages && <Row className="product-detail">
              <Column desktop={12} tablet={12} mobile={12}>
                <CarouselWrapper
                  slideToShow={1}
                  slideToShowTablet={1}
                  className="product-detail-images">
                  {prodImages?.map((image: IImageProps, index: number) => (
                    <Img
                      type="pdp"
                      styledimage={(image?.src && isPimProduct) ? null : image.styledimage ?? image.image_style_uri}
                      alt={image.alt}
                      src={image?.src}
                      key={index}
                      caption={isPimProduct ? "" : image.caption}
                      width={768}
                      height={432}
                      className="product-detail-actual-image"
                    />
                  ))}
                </CarouselWrapper>
              </Column>
            </Row>}
            <Row>
              <Column desktop={12} tablet={12} mobile={12} className="product-detail-meta">
                <Row>
                  <Column desktop={8} tablet={8} mobile={8} className="product-detail-meta">
                    {productCategory && (
                      <div className="product-detail-taxonomy">
                        <Taxonomy itemsToDisplay={2} taxonomies={props?.pdTagsTaxonomies} />
                      </div>
                    )}
                  </Column>

                  {isPimProduct ? (
                    <Column
                      desktop={4}
                      tablet={4}
                      mobile={4}
                      className="material-teaser-card-content-icon-container">
                      {bookmark && (
                        <div className="event-card-vertical-content-icon">
                          <BookmarkWithButton
                            id={id}
                            {...bookmark}
                            bookmarkCallback={handleBookmarks}
                          />
                        </div>
                      )}
                      <div className="event-card-vertical-content-icon">
                        <div className="material-teaser-card-content-favorite-section">
                          <span className="favorite-icon">
                            {applicationConfigContext?.applicationConfiguration
                              ?.isFavouriteEnabled && (
                                <FavoriteIcon
                                  id={id}
                                  totalFavoriteCount={totalFavoriteCount}
                                  favouriteStatus={isFavouriteSelected}
                                  favoriteHandlerCallback={favouriteHandler}
                                />
                              )}
                          </span>
                        </div>
                      </div>
                    </Column>
                  ) : (
                    <></>
                  )}
                </Row>

                {isPimProduct && productManager ? (
                  <h4 className="product-detail-manager">{productManager}</h4>
                ) : (
                  <></>
                )}
              </Column>

              <Column desktop={12} tablet={12} mobile={12} className="product-detail-meta">
                {title && <h1 className="product-detail-title">{title}</h1>}

                {isPimProduct ? (
                  <>
                  {headeractiveIngredientInfo?.length > 0 && headeractiveIngredientInfo?.map((teaser: any, index: number) => (
                    <div className={"product-detail-ingredient-group"} key={ index }>
                    {getCSVPaywalledData(teaser?.title) && getCSVPaywalledData(teaser?.description) &&  (
                      <h3 className={`product-detail-active-ingredient ${teaser.full_row_display ? "product-detail-ingredient-full" : ""}`}>{getCSVPaywalledData(teaser.title)}:&nbsp; </h3>
                    )}
                    {teaser?.description && (
                      <h3 className="product-detail-active-ingredient-desc">
                        <RichText
                          className="product-detail-active-ingredient-desc"
                          content={getCSVPaywalledData(teaser.description)}
                        />
                      </h3>
                    )}
                    </div>
                  ))}
                  </>
                ) : (
                  <></>
                )}
                {isPimProduct && (!headeractiveIngredientInfo || headeractiveIngredientInfo?.length == 0) && activeIngredientHeading && activeIngredientDescription ? (
                  <h3 className="product-detail-active-ingredient">{activeIngredientHeading}: </h3>
                ) : (
                  <></>
                )}

                {isPimProduct && (!headeractiveIngredientInfo || headeractiveIngredientInfo?.length == 0) && activeIngredientDescription ? (
                  <h3 className="product-detail-active-ingredient-desc">
                    <RichText
                      className="product-detail-active-ingredient-desc"
                      content={activeIngredientDescription}
                    />
                  </h3>
                ) : (
                  <></>
                )}

                {productContent?.content && (
                  <div className="product-detail-desc">
                    <RichText content={productContent?.content} />
                  </div>
                )}
              </Column>
            </Row>
          </Container>
          <Container className="product-detail-specification-data" narrow>
            <Row>
              <Column
                desktop={12}
                tablet={12}
                mobile={12}
                className="pdp-col product-detail-meta">
                <div className="product-detail-icontags">
                  {iconTags?.map((icoTag: IIcontagProps, index: number) => (
                    <IconTag key={index} {...icoTag} />
                  ))}
                </div>
              </Column>

              <Column
                desktop={12}
                tablet={12}
                mobile={12}
                className="pdp-col product-detail-overview-title">
                {productOverViewHeading ? <h2 className="product-detail-header">{productOverViewHeading}</h2> : <></>}
              </Column>

              {sandozVersion?.records && sandozVersion?.records?.length > 0 ? (
                <Column
                  desktop={6}
                  tablet={6}
                  mobile={12}
                  className="pdp-col product-detail-overview-sandoz">
                  {sandozVersion?.label && (
                    <h3 className="product-detail-overview-sandoz-title">{sandozVersion?.label}</h3>
                  )}
                  <div className="product-detail-overview-sandoz-spec">
                    {sandozVersion?.records.map((record: any, index: number) => (
                      <SpecificationItem
                        title={record?.question}
                        description={record?.answer}
                        key={index}
                      />
                    ))}
                  </div>
                </Column>
              ) : (
                <></>
              )}

              {originalVersion?.records && originalVersion?.records?.length > 0 ? (
                <Column
                  desktop={6}
                  tablet={6}
                  mobile={12}
                  className="pdp-col product-detail-overview-org">
                  {originalVersion?.label && (
                    <h3 className="product-detail-overview-sandoz-title">
                      {originalVersion?.label}
                    </h3>
                  )}
                  <div className="product-detail-overview-org-spec">
                    {originalVersion?.records.map((record: any, index: number) => (
                      <SpecificationItem
                        title={record?.question}
                        description={record?.answer}
                        key={index}
                      />
                    ))}
                  </div>
                </Column>
              ) : (
                <></>
              )}

              {(sandozVersion?.records && sandozVersion?.records?.length > 0) ||
                (originalVersion?.records && originalVersion?.records?.length > 0) ||
                productOverViewHeading ? (
                <Seperator />
              ) : (
                <></>
              )}

              {prodInfoTaxonomies?.length > 0 ? (
                <Column
                  desktop={12}
                  tablet={12}
                  mobile={12}
                  className="pdp-col product-detail-prod-info">
                  {prodInfoHeading && (
                    <h2 className="product-detail-prod-info-title">{prodInfoHeading}</h2>
                  )}
                  {prodInfoTaxonomies?.length > 0 ? (
                    <div className="product-detail-prod-info-tax">
                      {prodInfoTaxonomies?.map((specTaxo: ISpecificationItem, index: number) => (
                        <SpecificationItem
                          key={index}
                          title={getCSVPaywalledData(specTaxo?.title)}
                          description={getCSVPaywalledData(specTaxo?.description)}
                          full_row_display={specTaxo?.full_row_display}
                        />
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </Column>
              ) : (
                <></>
              )}
              {packInfoTaxonomies?.length > 0 &&
                !nullCheckforDesc(packInfoTaxonomies, "description") ? (
                <Column
                  desktop={12}
                  tablet={12}
                  mobile={12}
                  className="pdp-col product-detail-pack-info">
                  {packInfoHeading && packInfoTaxonomies && (
                    <h2 className="product-detail-pack-info-title">{packInfoHeading}</h2>
                  )}

                  {packInfoTaxonomies?.length > 0 ? (
                    <div className="product-detail-pack-info-tax">
                      {packInfoTaxonomies?.map((specTaxo: ISpecificationItem, index: number) => (
                        <SpecificationItem
                          key={index}
                          title={getCSVPaywalledData(specTaxo?.title)}
                          description={getCSVPaywalledData(specTaxo?.description)}
                          full_row_display={specTaxo?.full_row_display}
                        />
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </Column>
              ) : (
                <></>
              )}
              {stInfoTaxonomies?.length > 0 ? (
                <Column
                  desktop={12}
                  tablet={12}
                  mobile={12}
                  className="pdp-col product-detail-stor-info">
                  {stInfoHeading && (
                    <h2 className="product-detail-prod-stor-title">{stInfoHeading}</h2>
                  )}
                  {stInfoTaxonomies?.length > 0 ? (
                    <div className="product-detail-prod-stor-tax">
                      {stInfoTaxonomies?.map((specTaxo: ISpecificationItem, index: number) => (
                        <SpecificationItem
                          key={index}
                          title={getCSVPaywalledData(specTaxo?.title)}
                          description={getCSVPaywalledData(specTaxo?.description)}
                          full_row_display={specTaxo?.full_row_display}
                        />
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </Column>
              ) : (
                <></>
              )}
              {byInfoTaxonomies?.length > 0 ? (
                <Column
                  desktop={12}
                  tablet={12}
                  mobile={12}
                  className="pdp-col product-detail-buy-info">
                  {byInfoHeading && (
                    <h2 className="product-detail-prod-buy-title">{byInfoHeading}</h2>
                  )}
                  {byInfoTaxonomies?.length > 0 ? (
                    <div className="product-detail-prod-buy-tax">
                      {byInfoTaxonomies?.map((specTaxo: ISpecificationItem, index: number) => (
                        <SpecificationItem
                          key={index}
                          title={getCSVPaywalledData(specTaxo?.title)}
                          description={getCSVPaywalledData(specTaxo?.description)}
                          full_row_display={specTaxo?.full_row_display}
                        />
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </Column>
              ) : (
                <></>
              )}
              {wdInfoTaxonomies?.length > 0 ? (
                <Column
                  desktop={12}
                  tablet={12}
                  mobile={12}
                  className="pdp-col product-detail-wholesale-info">
                  {wdInfoHeading && (
                    <h2 className="product-detail-prod-wholesale-title">{wdInfoHeading}</h2>
                  )}
                  {wdInfoTaxonomies?.length > 0 ? (
                    <div className="product-detail-prod-wholesale-tax">
                      {wdInfoTaxonomies?.map((specTaxo: ISpecificationItem, index: number) => (
                        <SpecificationItem
                          key={index}
                          title={getCSVPaywalledData(specTaxo?.title)}
                          description={getCSVPaywalledData(specTaxo?.description)}
                          full_row_display={specTaxo?.full_row_display}
                        />
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </Column>
              ) : (
                <></>
              )}

              {/* Load the Other details about the product */}
              {otherInfoTaxonomies && (
                <>
                  {otherInfoTaxonomies.map((item: any, index: number) => (
                    <Column
                      desktop={12}
                      tablet={12}
                      mobile={12}
                      key={index}
                      className="pdp-col pim-product-detail-prod-info">
                      {item?.group && item?.groupvalues && (
                        <h2 className="product-detail-prod-info-title">{item?.group}</h2>
                      )}
                      {item?.groupvalues?.length > 0 ? (
                        <div className="product-detail-prod-info-tax pim-info">
                          {item?.groupvalues?.map((specTaxo: ISpecificationItem, index: number) => (
                            <SpecificationItem
                              key={index}
                              title={getCSVPaywalledData(specTaxo?.title)}
                              description={getCSVPaywalledData(specTaxo?.description)}
                              full_row_display={specTaxo?.full_row_display}
                            />
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Column>
                  ))}
                </>
              )}
            </Row>
          </Container>
          {isPimProduct && barcodeDetails?.length > 0 && (
            <Container className="product-detail-codes" narrow>
              <hr />
              <Row>
                <Column
                  desktop={12}
                  tablet={12}
                  mobile={12}
                  className="pdp-col product-detail-codes-bar">
                  <>
                    <Seperator />
                    {barcodeDetails?.map((data: any, index: number) => (
                      <Column desktop={4} tablet={4} mobile={4} key={index}>
                        <BarcodeCollection key={index} {...data} isPimProduct />
                      </Column>
                    ))}
                  </>
                </Column>
              </Row>
            </Container>
          )}
          {isPimProduct && sanImgAddImages?.length > 0 && (
            <div className="bg-grey product-detail-image-gallery">
              <Container className="product-detail-image-gallery-wrapper">
                <Row>
                  <Column desktop={12} tablet={12} mobile={12}>
                    <ImageGrid
                      imageData={sanImgAddImages}
                      gridType={getImageGridType(sanImgAddImages)}
                      isPimProduct
                    />
                  </Column>
                </Row>
              </Container>
            </div>
          )}
          {!isPimProduct && imageGrid && (
            <div className="bg-grey product-detail-image-gallery">
              <Container className="product-detail-image-gallery-wrapper">
                <Row>
                  <Column desktop={12} tablet={12} mobile={12}>
                    <ImageGrid imageData={imageGrid} gridType={getImageGridType(imageGrid)} />
                  </Column>
                </Row>
              </Container>
            </div>
          )}
          {(variousCodes && variousCodes?.records?.length > 0) ||
            (barCodes && barCodes?.length > 0) ? (
            <Container className="product-detail-codes" narrow>
              <Row>
                {variousCodes && variousCodes?.records?.length > 0 ? (
                  <Column
                    desktop={12}
                    tablet={12}
                    mobile={12}
                    className="pdp-col product-detail-codes-various">
                    {variousCodes?.label && (
                      <h3 className="product-detail-various-code-title">{variousCodes?.label}</h3>
                    )}
                    <div className="product-detail-overview-org-spec">
                      {variousCodes?.records.map((record: any, index: number) => (
                        <SpecificationItem
                          key={index}
                          title={record?.question}
                          description={record?.answer}
                        />
                      ))}
                    </div>
                  </Column>
                ) : (
                  <></>
                )}
                {
                  barCodes && barCodes?.length > 0 ? (
                    <Column
                      desktop={12}
                      tablet={12}
                      mobile={12}
                      className="pdp-col product-detail-codes-bar">
                      {barCodes?.map((data: any, index: number) => {
                        return <BarcodeCollection key={index} {...data} />
                      })}
                    </Column>
                  ) : (
                    <></>
                  )
                }
              </Row >
            </Container >
          ) : (
            <></>
          )}
          {
            ENABLE_DISABLE_VALUE[showInCardStatus as keyof typeof ENABLE_DISABLE_VALUE] && (
              <Container>
                <div className="scrollable-content product-detail-table">
                  {showInCard ? renderTable() : <></>}
                </div>
              </Container>
            )
          }
          {
            ctaButtonGroup && ctaButtonGroup?.records?.length > 0 ? (
              <div className="bg-grey product-detail-cta-collections">
                <Container id={`attachid_${id}`} className="product-detail-cta-wrapper" narrow>
                  <Row>
                    <Column
                      desktop={12}
                      tablet={12}
                      mobile={12}
                      className="pdp-col product-detail-action-area">
                      <h3 className="product-detail-cta-collections-label">
                        {ctaButtonGroup?.materialButtonsHeading}
                      </h3>
                      <ButtonGroup>
                        {ctaButtonGroup?.records?.map((btn: any, index: number) => {
                          return (
                            <AnchorButton
                              key={index}
                              tabindex={1}
                              href={btn?.uri}
                              isExternal={btn?.options?.attributes?.target !== "_self"}
                              icon={
                                btn?.options?.attributes?.target === "_self" ? (
                                  <Icon iconName="DownloadIcon" />
                                ) : (
                                  <Icon iconName="ArrowAngledIconLeft" />
                                )
                              }
                              className="product-detail-cta"
                              download={btn?.options?.attributes?.target === "_self"}
                              isSecondary>
                              {btn?.title}
                            </AnchorButton>
                          )
                        })}
                      </ButtonGroup>
                    </Column>
                  </Row>
                </Container>
              </div>
            ) : (
              <></>
            )
          }
          {
            pdfButtonGroup && pdfButtonGroup?.length > 0 ? (
              <div className="bg-grey product-detail-cta-collections">
                <Container  id={`attachid_${id}`} className="product-detail-cta-wrapper" narrow>
                  <Row>
                    <Column
                      desktop={12}
                      tablet={12}
                      mobile={12}
                      className="pdp-col product-detail-action-area">
                      {pdfButtonGroupHeading && (
                        <h5 className="product-detail-cta-collections-label">
                          {pdfButtonGroupHeading}
                        </h5>
                      )}
                      <ButtonGroup>
                        {pdfButtonGroup?.map((btn: any, index: number) => {
                          if(!btn?.url) return <></>
                          return (
                            <AnchorButton
                              key={index}
                              tabindex={1}
                              href={btn?.url}
                              icon={<Icon iconName="DownloadIcon" />}
                              className="product-detail-cta"
                              download={true}
                              isExternal={false}
                              isSecondary>
                              {btn?.label}
                            </AnchorButton>
                          )
                        })}
                      </ButtonGroup>
                    </Column>
                  </Row>
                </Container>
              </div>
            ) : (
              <></>
            )
          }
          {
            notesAboutProduct?.value || latestUpdates ? (
              <Container className="product-detail-foot-info" narrow>
                <Row>
                  {latestUpdates && latestUpdates?.value?.value ? (
                    <Column
                      desktop={12}
                      tablet={12}
                      mobile={12}
                      className="pdp-col product-detail-latest-news">
                      {latestUpdates?.label ? (
                        <h3 className="product-detail-latest-news-title">{latestUpdates?.label}</h3>
                      ) : (
                        <></>
                      )}
                      <ContentWithScroll content={latestUpdates?.value?.value} />
                    </Column>
                  ) : (
                    <></>
                  )}
                  {notesAboutProduct?.value ? (
                    <Column
                      desktop={12}
                      tablet={12}
                      mobile={12}
                      className="pdp-col product-detail-notes">
                      <TextBlock
                        className="product-detail-notes-content"
                        paragraph={notesAboutProduct?.value}
                        alignment="left"
                        finePrint={true}
                      />
                    </Column>
                  ) : (
                    <></>
                  )}
                </Row>
              </Container>
            ) : (
              <></>
            )
          }
          {
            primaryButtonUrl || secondaryButtonUrl || tertiaryButtonUrl ? (
              <Container className="product-detail-cta-group" narrow>
                <Row>
                  <Column
                    desktop={12}
                    tablet={12}
                    mobile={12}
                    className="pdp-col product-detail-util-btns cta-print">
                    <ButtonGroup>
                      {primaryButtonUrl ? (
                        <AnchorButton
                          tabindex={1}
                          href={primaryButtonUrl}
                          isExternal={primaryIsExternalUrl}
                          icon={<Icon iconName="ArrowAngledIconLeft" />}
                          className="product-detail-cta"
                          isSecondary>
                          {primaryButtonLabel}
                        </AnchorButton>
                      ) : (
                        <></>
                      )}
                      {secondaryButtonUrl ? (
                        <AnchorButton
                          tabindex={2}
                          href={secondaryButtonUrl}
                          isExternal={secondaryIsExternalUrl}
                          icon={<Icon iconName="PrintIcon" />}
                          className="product-detail-cta pdp-print"
                          isSecondary>
                          {secondaryButtonLabel}
                        </AnchorButton>
                      ) : (
                        <></>
                      )}
                      {tertiaryButtonUrl ? (
                        <AnchorButton
                          tabindex={3}
                          href={tertiaryButtonUrl}
                          isExternal={tertiaryIsExternalUrl}
                          icon={<Icon iconName="DownloadIcon" />}
                          className="product-detail-cta pdp-download"
                          isSecondary>
                          {tertiaryButtonLabel}
                        </AnchorButton>
                      ) : (
                        <></>
                      )}
                    </ButtonGroup>
                  </Column>
                </Row>
              </Container>
            ) : (
              <></>
            )
          }
          {
            footerHeading || footerDescription ? (
              <Container>
                <>
                  {footerHeading && (
                    <h4 className="product-detail-fine-print-title">{footerHeading}</h4>
                  )}
                  {footerDescription && (
                    <h4 className="product-detail-fine-print-desc">{footerDescription}</h4>
                  )}
                </>
              </Container>
            ) : (
              <></>
            )
          }
          {
            !user?.isLoggedIn &&
            (paywallPosition === "bottom" || paywallPosition === "both") &&
            fieldPaywallRoles &&
            fieldPaywallRoles.length > 0 && (
              <>
                {applicationConfigContext?.applicationConfiguration?.paywallNotice && (
                  <Container>
                    <Row isTemplateWrapper>
                      <PaywallNotification
                        paywallNotice={
                          applicationConfigContext?.applicationConfiguration?.paywallNotice
                        }
                        roles={filterdRoles}
                        loginHandler={() => setShowLoginPopUp(true)}
                      />
                    </Row>
                  </Container>
                )}
              </>
            )
          }
          {
            finePrint && finePrint?.length > 0 && !isFinePrintPaywalled(finePrint) && (
              <Container id={`fineprint_${id}`}>
                <Row isTemplateWrapper className="pdp-col product-detail-fine-print">
                  {finePrint?.map((list: any, index: number) => (
                    <>
                      {list?.title || list?.description ? (
                        <div key={index} className="product-detail-product-fine-print">
                          {list?.title ? (
                            <RichText
                              className="product-detail-fine-print-title"
                              content={getCSVPaywalledData(list.title)}
                            />
                          ) : (
                            <></>
                          )}
                          {list?.description ? (
                            <RichText
                              className="product-detail-fine-print-desc"
                              content={getCSVPaywalledData(list.description)}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  {finePrint &&
                    finePrint?.length > 0 &&
                    productID &&
                    basistextLabel &&
                    !isSomeFinePrintPaywalled(finePrint) && (
                      <Anchor
                        href={`/basistext?basistextID=${productID}`}
                        tabindex={0}
                        isExternal={true}>
                        <h4 className="legacy-basis-text">{basistextLabel}</h4>
                      </Anchor>
                    )}
                </Row>
              </Container>
            )
          }
          {referenceText && (
            <div className="container product-detail-reference-text">
              <RichText content={referenceText} />
            </div>
          )}
          <LoginPopup
            popup={popupProps}
            form={formProps}
            bannerCTA={bannerCTAProps}
            showPopup={showLoginPopUp}
            handlePopupShow={(popupShow, event: any) => {
              setShowLoginPopUp(popupShow)
            }}
            handleForgotPasswordShow={handleForgotPassword}
            loginConfirmation={handleConfirmation}
          />
        </>
      )}
    </div >
  )
}

const mapDispatch = {
  setSession: (session: string, csrf: string) => setSession(session, csrf),
  addUserData: (user: any) => addUser(user),
  resetUserData: (user: any) => resetUser(user),
  resetRatings: (ratings: any) => resetRatings(ratings),
  setUserProfile: (profile: any) => setUserProfile(profile),
  resetUserAndProfile: (user: any) => resetUser(user),
  updateRatingsCount: (ratings: any) => getRatingsCount(ratings),
  updatePaywallContent: (isPaywall: boolean) => updatePaywallContent(isPaywall),
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) =>
    setUserStateGlobally(loggedIn, loggedInType),
}

const mapState = (state: any) => ({
  user: state.user,
  ratings: state.ratings,
})

export default connect(mapState, mapDispatch)(CSVJapanProductDetail)
